.attendees {
  &__attendees-container {
    overflow: hidden;

    &--with-others {
      &::after {
        display: inline;
        content: attr(attr-other-attendees);
        font-size: inherit;
        line-height: inherit;
        white-space: nowrap;
      }
    }
  }

  &__attendees {
    display: flex;
    overflow: hidden;
  }

  &__attendee {
    white-space: nowrap;
    &--hidden {
      display: none;
    }
    &:not(:last-of-type) {
      &::after {
        display: inline;
        content: ";";
        margin-right: 4px;
      }
    }
  }
}
