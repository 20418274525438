.dx-scrollable-scrollbar {
  border-radius: 8px;
}

.dx-scrollable-wrapper .dx-scrollbar-hoverable.dx-scrollable-scrollbar-active .dx-scrollable-scroll-content {
  background-color: #289fe7;
}
.dx-scrollable-wrapper .dx-scrollable-scroll-content {
  background-color: #289fe7;
  border-radius: 8px;
}

.dx-layout-manager .dx-label-h-align .dx-field-item-label {
  white-space: normal;
}
.dx-layout-manager .dx-label-h-align .dx-field-item-label .dx-field-item-label-content {
  max-width: 100%;
}

.dx-field-item-required .dx-label > span::after {
  content: " *";
}

.dx-accordion-item {
  border-left-color: #ddd;
  border-right-color: #ddd;
}

.dx-daterangebox.dx-invalid .dx-editor-outlined.dx-state-focused.dx-widget::before,
.dx-daterangebox.dx-invalid .dx-editor-outlined.dx-state-active.dx-widget::before,
.dx-daterangebox.dx-invalid .dx-editor-outlined.dx-state-hover.dx-widget::before,
.dx-daterangebox .dx-editor-outlined.dx-state-focused.dx-widget::before,
.dx-daterangebox .dx-editor-outlined.dx-state-active.dx-widget::before,
.dx-daterangebox .dx-editor-outlined.dx-state-hover.dx-widget::before {
  left: -3px;
  bottom: 4px;
}

.dx-select-checkbox.dx-checkbox.dx-checkbox-checked .dx-checkbox-icon, .dx-select-checkbox.dx-checkbox.dx-checkbox-indeterminate .dx-checkbox-icon {
  font-size: 12px !important;
  font: 16px/1em DXIcons;
  color: #fff !important;
  text-align: center;
  background-color: #337ab7;
}
.dx-select-checkbox.dx-checkbox.dx-checkbox-checked .dx-checkbox-icon::before, .dx-select-checkbox.dx-checkbox.dx-checkbox-indeterminate .dx-checkbox-icon::before {
  content: "\f074";
  position: relative;
  display: block;
  width: 1em;
  top: 50%;
  height: unset;
  margin-top: -0.5em;
  left: 50%;
  margin-left: -0.5em;
  background-color: unset;
}
.dx-select-checkbox.dx-checkbox.dx-checkbox-checked .dx-checkbox-icon::before {
  content: "\f005";
}

.ag-select-box-drop-down .dx-popup-normal {
  min-width: min-content;
}
.ag-select-box-drop-down .dx-popup-normal .dx-item.dx-list-item {
  width: auto;
}
.ag-select-box-drop-down .dx-popup-normal .dx-item.dx-list-item .dx-item-content.dx-list-item-content {
  width: min-content;
}

.ag-summary-table {
  --ag-summary-table-min-width: 200px;
  min-width: var(--ag-summary-table-min-width);
}
.ag-summary-table .dx-toolbar-items-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  min-height: 36px;
  height: auto;
}
.ag-summary-table .dx-toolbar-items-container .dx-toolbar-before,
.ag-summary-table .dx-toolbar-items-container .dx-toolbar-center,
.ag-summary-table .dx-toolbar-items-container .dx-toolbar-after {
  position: static;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}