@import "variables";

ngx-skeleton-loader {
  display: flex;
  flex-direction: row;
  gap: calc(#{$base-padding} / 2);

  .loader.progress {
    margin-bottom: 0;
  }
}
