// Filter Builder Display value (used with filterBuilderGroupDisplayValue pipe)
.ag-filter-builder {
  .filter-item-field,
  .filter-item-operator,
  .filter-item-value {
    display: inline-block;
    white-space: pre;
    border-radius: 6px;
    padding: 1px 3px;
  }

  .filter-item-field {
    background-color: rgba(51, 122, 183, 0.3);
  }

  .filter-item-operator {
    background-color: rgba(92, 184, 92, 0.3);
  }

  .filter-item-value {
    background-color: rgba(221, 221, 221, 0.5);
  }
}
