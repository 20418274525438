.ag-main-info {
  --ag-main-info-dx-icon-color: #ddd;
  --ag-main-info-dx-gutters: 10px;

  margin: 0;
  padding: 0;

  &__image {
    width: 70px;
    height: 80px;
    object-fit: cover;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  }

  &__title,
  &__text {
    margin-bottom: 0;
  }

  &__title {
    font-size: 15px;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: var(--ag-main-info-dx-gutters);
  }

  &__icon {
    .dx-icon {
      color: var(--ag-main-info-dx-icon-color);
    }
  }

  &__footer {
    margin-top: var(--ag-main-info-dx-gutters);
  }
}
