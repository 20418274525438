$base-padding: 8px;
$ag-sidenav-box-shadow: 0 4px 12px 0 rgba(0,0,0,0.07);
$ag-border-color: rgba(0,0,0,0.07);

@mixin tab-with-text-area($area-position: last-of-type) {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .dx-layout-manager {
    height: 100%;
    & > .dx-widget {
      & > .dx-collection {
        & > .dx-item {
          &:not(:#{$area-position}) {
            flex-grow: 0 !important;
          }
        }
      }
    }
  }
  .dx-textarea {
    height: 100%;
  }
}
