.superscript-icon {
  vertical-align: super;
  color: #2e7ae4;
  font-size: 18px;
}

.trophy-icon {
  font-size: 22px;
  color: #2e7ae4;
}

.ag-status-icon {
  --ag-status-dx-icon-color: #ddd;
  --ag-status-dx-gutters: 10px;
  --ag-status-icon-size: 18px;

  color: var(--ag-status-dx-icon-color);
  font-size: var(--ag-status-icon-size);

  .dx-button-mode-text {
    cursor: default; // no actions
  }

  .dx-icon {
    color: var(--ag-status-dx-icon-color);
  }

  &.danger {
    --ag-status-dx-icon-color: #d43f3a;
  }

  &.success {
    --ag-status-dx-icon-color: #4cae4c;
  }
}
