.editor-progress-indicator {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & &__indicator {
    padding: 0;

    &--absolute {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
