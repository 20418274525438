.grow-scroll {
  .dx-scrollable-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
  }
}
