$icomoon-font-family: "agi" !default;
$icomoon-font-path: "/assets/fonts/ag-icons" !default;

$dx-icon-agi-exchange: unquote('"\\e92d"');
$dx-icon-agi-display: unquote('"\\e929"');
$dx-icon-agi-mobile: unquote('"\\e92a"');
$dx-icon-agi-devices-laptop: unquote('"\\e92b"');
$dx-icon-agi-widgets: unquote('"\\e92c"');
$dx-icon-agi-qr_code_scanner: unquote('"\\e928"');
$dx-icon-agi-crop: unquote('"\\f125"');
$dx-icon-agi-rock-and-roll: unquote('"\\e925"');
$dx-icon-agi-medal: unquote('"\\e924"');
$dx-icon-agi-location: unquote('"\\e913"');
$dx-icon-agi-hotel: unquote('"\\e914"');
$dx-icon-agi-billing-address: unquote('"\\e91d"');
$dx-icon-agi-shipping-address: unquote('"\\e91e"');
$dx-icon-agi-flight_land: unquote('"\\e91f"');
$dx-icon-agi-flight_takeoff: unquote('"\\e920"');
$dx-icon-agi-zoom-in: unquote('"\\e919"');
$dx-icon-agi-upload: unquote('"\\e912"');
$dx-icon-agi-download: unquote('"\\e918"');
$dx-icon-agi-storefront-o: unquote('"\\e915"');
$dx-icon-agi-details: unquote('"\\e917"');
$dx-icon-agi-logs: unquote('"\\e91a"');
$dx-icon-agi-participants: unquote('"\\e972"');
$dx-icon-agi-participant-plus: unquote('"\\e973"');
$dx-icon-agi-participant-minus: unquote('"\\e974"');
$dx-icon-agi-participant-check: unquote('"\\e975"');
$dx-icon-agi-calendar: unquote('"\\e90c"');
$dx-icon-agi-calendar-day-solid: unquote('"\\e921"');
$dx-icon-agi-calendar-check-solid: unquote('"\\e922"');
$dx-icon-agi-map-location-dot-solid: unquote('"\\e923"');
$dx-icon-agi-folder-plus: unquote('"\\e91c"');
$dx-icon-agi-file-text: unquote('"\\f0f6"');
$dx-icon-agi-file-image: unquote('"\\f1c5"');
$dx-icon-agi-folder-open: unquote('"\\f07c"');
$dx-icon-agi-plus: unquote('"\\f067"');
$dx-icon-agi-paper-plane: unquote('"\\e900"');
$dx-icon-agi-comments: unquote('"\\e901"');
$dx-icon-agi-building: unquote('"\\e902"');
$dx-icon-agi-calculator: unquote('"\\e903"');
$dx-icon-agi-shield-halved: unquote('"\\e904"');
$dx-icon-agi-filter-m: unquote('"\\e909"');
$dx-icon-agi-bullhorn: unquote('"\\e90a"');
$dx-icon-agi-graduation-cap: unquote('"\\e90b"');
$dx-icon-agi-heart: unquote('"\\f004"');
$dx-icon-agi-heart-o: unquote('"\\f08a"');
$dx-icon-agi-trophy: unquote('"\\f091"');
$dx-icon-agi-home: unquote('"\\f015"');
$dx-icon-agi-cog: unquote('"\\f013"');
$dx-icon-agi-envelope: unquote('"\\f0e0"');
$dx-icon-agi-globe: unquote('"\\e907"');
$dx-icon-agi-star: unquote('"\\f006"');
$dx-icon-agi-tag: unquote('"\\f02b"');
$dx-icon-agi-folder: unquote('"\\f07b"');
$dx-icon-agi-trash-alt: unquote('"\\f014"');
$dx-icon-agi-inbox: unquote('"\\f01c"');
$dx-icon-agi-shipping-fast: unquote('"\\e905"');
$dx-icon-agi-user-circle: unquote('"\\f2bd"');
$dx-icon-agi-user: unquote('"\\f007"');
$dx-icon-agi-user-gear: unquote('"\\e906"');
$dx-icon-agi-people-group: unquote('"\\e908"');
$dx-icon-agi-file-csv: unquote('"\\e90d"');
$dx-icon-agi-copy: unquote('"\\e90e"');
$dx-icon-agi-comment-dots: unquote('"\\f27a"');
$dx-icon-agi-ban: unquote('"\\f05e"');
$dx-icon-agi-thumbs-up: unquote('"\\e90f"');
$dx-icon-agi-thumbs-down: unquote('"\\e910"');
$dx-icon-agi-twitter: unquote('"\\f099"');
$dx-icon-agi-linkedin-in: unquote('"\\f0e1"');
$dx-icon-agi-whatsapp: unquote('"\\f232"');
$dx-icon-agi-instagram: unquote('"\\f16d"');
$dx-icon-agi-facebook-f: unquote('"\\f09a"');
$dx-icon-agi-telegram-plane: unquote('"\\f2c6"');
$dx-icon-agi-circle-check: unquote('"\\f058"');
$dx-icon-agi-exclamation-circle: unquote('"\\f06a"');
$dx-icon-agi-circle-pause: unquote('"\\f28b"');
$dx-icon-agi-circle-question: unquote('"\\f059"');
$dx-icon-agi-circle-play: unquote('"\\f144"');
$dx-icon-agi-key: unquote('"\\e911"');
$dx-icon-agi-up-right-and-down-left-from-center: unquote('"\\f065"');
$dx-icon-agi-eye: unquote('"\\f06e"');
$dx-icon-agi-eye-slash: unquote('"\\f070"');
$dx-icon-agi-rotate-right: unquote('"\\f01e"');
$dx-icon-agi-filter: unquote('"\\f0b0"');
$dx-icon-agi-lock: unquote('"\\f023"');
$dx-icon-agi-address-card: unquote('"\\f2bc"');
$dx-icon-agi-wrench: unquote('"\\e916"');
$dx-icon-agi-tools: unquote('"\\e91b"');
$dx-icon-agi-picture: unquote('"\\f03e"');
$dx-icon-agi-claim: unquote('"\\e926"');
$dx-icon-agi-verified: unquote('"\\e927"');
$dx-icon-agi-list-add: unquote('"\\e92e"');
$dx-icon-agi-list-remove: unquote('"\\e92f"');
