@import "variables";

.main-layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  &__title {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 700;
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    min-height: 0;
    padding: calc($base-padding * 2);
    background-color: #fff;
    border-radius: 0 0 $base-padding $base-padding;
  }

  &__content {
    min-height: 0;
    flex-grow: 1;
    padding: calc($base-padding * 2) 0;
    &--modal {
      padding: 0;
    }
  }

  &__button {
    &--align-left {
      margin-right: auto;
    }
  }

  &__buttons-container {
    display: flex;
    flex-direction: row;
    grid-column-gap: calc($base-padding * 2);
    justify-content: flex-end;
    padding-top: calc($base-padding * 2);

    & > * {
      min-width: 150px;
    }
  }
}

.ag-empty-text {
  color: #888;
}

// for editor without grid
.editor-empty-text {
  display: block;
  color: #999;
  font-size: 17px;
  text-align: center;
  padding-bottom: 18px;
  padding-top: 30px;
}
