.dx-field-item {
  &.dx-flex-layout {
    &.form-items {
      &__checkbox {
        &--label-right {
          padding-top: 7px;

          .dx-field-item-content {
            flex-grow: 0;
            width: max-content;
          }
        }
      }
    }
  }

  & .dx-state-readonly {
    background-color: #eef5f9;
    .dx-texteditor-input {
      background-color: #eef5f9;
    }
  }
}

.dx-edit-row {
  .dx-state-readonly {
    &.dx-checkbox {
      .dx-checkbox-icon {
        background-color: #eef5f9;
      }
    }
  }
}
.dx-state-readonly {
  .dx-texteditor-input-container {
    .dx-texteditor-input {
      background-color: #eef5f9;
    }
  }
}

.dx-show-clear-button {
  .dx-clear-button-area {
    min-width: 22px;
    width: min-content;

    .dx-icon-clear {
      width: min-content;
      padding: 8px 4px;
      font-size: 14px;
    }
  }
}
