.ag-kanban {
  &__card {
    &.card {
      position: relative;
      background-color: #fff;
      box-sizing: border-box;
      width: calc(100% - 15px);
      padding: 10px 20px;
      margin: 10px;
    }

    .container-fluid {
      padding-left: 0;
    }

    .card-priority {
      --ag-card-priority-color: #adadad;

      position: absolute;
      top: 10px;
      bottom: 10px;
      left: 5px;
      width: 5px;
      border-radius: 2px;
      background-color: var(--ag-card-priority-color);

      &--low {
        --ag-card-priority-color: #86c285;
      }

      &--medium {
        --ag-card-priority-color: #edc578;
      }

      &--hight {
        --ag-card-priority-color: #ef7d59;
      }
    }

    .card-assignee {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      color: #adadad;
      word-break: break-word;
    }

    .card {
      &__header {
        --ag-header-gutters: 4px;

        display: flex;
        align-items: flex-start;
        gap: var(--ag-header-gutters);
        flex-wrap: nowrap;

        .dx-button {
          padding: 0;

          &.dx-button-has-icon {
            .dx-button-content {
              padding: 5px;
            }
          }
        }
      }

      &__header-title {
        margin-right: auto;
        margin-top: 3px;
        text-transform: capitalize;
        padding-right: var(--ag-header-gutters);
      }

      &__footer {
        display: flex;
        align-items: center;
        gap: 5px;
        padding-top: 15px;

        .col-auto {
          padding-right: 0;
          &:not(:first-child) {
            padding-left: 0;
          }
        }

        .dx-button {
          padding: 0;

          .dx-button-content {
            padding: 2px;
            border-radius: 50%;
            min-width: 20px;
          }
        }
      }
    }
  }
}

.ag-text-clickable {
  cursor: pointer;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none !important;
  }
}
