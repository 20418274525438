@import "variables";

.paper {
  padding: $base-padding;
  border-radius: calc(2 * #{$base-padding});
  background-color: #fff;
  &__content {
    padding: calc(#{$base-padding} / 2);
  }
}
