@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?wc0s6g') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?wc0s6g') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?wc0s6g##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="dx-icon-agi-"], [class*=" dx-icon-agi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dx-icon-agi-exchange {
  &:before {
    content: $dx-icon-agi-exchange; 
  }
}
.dx-icon-agi-display {
  &:before {
    content: $dx-icon-agi-display; 
  }
}
.dx-icon-agi-mobile {
  &:before {
    content: $dx-icon-agi-mobile; 
  }
}
.dx-icon-agi-devices-laptop {
  &:before {
    content: $dx-icon-agi-devices-laptop; 
  }
}
.dx-icon-agi-widgets {
  &:before {
    content: $dx-icon-agi-widgets; 
  }
}
.dx-icon-agi-qr_code_scanner {
  &:before {
    content: $dx-icon-agi-qr_code_scanner; 
  }
}
.dx-icon-agi-crop {
  &:before {
    content: $dx-icon-agi-crop; 
  }
}
.dx-icon-agi-rock-and-roll {
  &:before {
    content: $dx-icon-agi-rock-and-roll; 
  }
}
.dx-icon-agi-medal {
  &:before {
    content: $dx-icon-agi-medal; 
  }
}
.dx-icon-agi-location {
  &:before {
    content: $dx-icon-agi-location; 
  }
}
.dx-icon-agi-hotel {
  &:before {
    content: $dx-icon-agi-hotel; 
  }
}
.dx-icon-agi-billing-address {
  &:before {
    content: $dx-icon-agi-billing-address; 
  }
}
.dx-icon-agi-shipping-address {
  &:before {
    content: $dx-icon-agi-shipping-address; 
  }
}
.dx-icon-agi-flight_land {
  &:before {
    content: $dx-icon-agi-flight_land; 
  }
}
.dx-icon-agi-flight_takeoff {
  &:before {
    content: $dx-icon-agi-flight_takeoff; 
  }
}
.dx-icon-agi-zoom-in {
  &:before {
    content: $dx-icon-agi-zoom-in; 
  }
}
.dx-icon-agi-upload {
  &:before {
    content: $dx-icon-agi-upload; 
  }
}
.dx-icon-agi-download {
  &:before {
    content: $dx-icon-agi-download; 
  }
}
.dx-icon-agi-storefront-o {
  &:before {
    content: $dx-icon-agi-storefront-o; 
  }
}
.dx-icon-agi-details {
  &:before {
    content: $dx-icon-agi-details; 
  }
}
.dx-icon-agi-logs {
  &:before {
    content: $dx-icon-agi-logs; 
  }
}
.dx-icon-agi-participants {
  &:before {
    content: $dx-icon-agi-participants; 
  }
}
.dx-icon-agi-participant-plus {
  &:before {
    content: $dx-icon-agi-participant-plus; 
  }
}
.dx-icon-agi-participant-minus {
  &:before {
    content: $dx-icon-agi-participant-minus; 
  }
}
.dx-icon-agi-participant-check {
  &:before {
    content: $dx-icon-agi-participant-check; 
  }
}
.dx-icon-agi-calendar {
  &:before {
    content: $dx-icon-agi-calendar; 
  }
}
.dx-icon-agi-calendar-day-solid {
  &:before {
    content: $dx-icon-agi-calendar-day-solid; 
  }
}
.dx-icon-agi-calendar-check-solid {
  &:before {
    content: $dx-icon-agi-calendar-check-solid; 
  }
}
.dx-icon-agi-map-location-dot-solid {
  &:before {
    content: $dx-icon-agi-map-location-dot-solid; 
  }
}
.dx-icon-agi-folder-plus {
  &:before {
    content: $dx-icon-agi-folder-plus; 
  }
}
.dx-icon-agi-file-text {
  &:before {
    content: $dx-icon-agi-file-text; 
  }
}
.dx-icon-agi-file-image {
  &:before {
    content: $dx-icon-agi-file-image; 
  }
}
.dx-icon-agi-folder-open {
  &:before {
    content: $dx-icon-agi-folder-open; 
  }
}
.dx-icon-agi-plus {
  &:before {
    content: $dx-icon-agi-plus; 
  }
}
.dx-icon-agi-paper-plane {
  &:before {
    content: $dx-icon-agi-paper-plane; 
  }
}
.dx-icon-agi-comments {
  &:before {
    content: $dx-icon-agi-comments; 
  }
}
.dx-icon-agi-building {
  &:before {
    content: $dx-icon-agi-building; 
  }
}
.dx-icon-agi-calculator {
  &:before {
    content: $dx-icon-agi-calculator; 
  }
}
.dx-icon-agi-shield-halved {
  &:before {
    content: $dx-icon-agi-shield-halved; 
  }
}
.dx-icon-agi-filter-m {
  &:before {
    content: $dx-icon-agi-filter-m; 
  }
}
.dx-icon-agi-bullhorn {
  &:before {
    content: $dx-icon-agi-bullhorn; 
  }
}
.dx-icon-agi-graduation-cap {
  &:before {
    content: $dx-icon-agi-graduation-cap; 
  }
}
.dx-icon-agi-heart {
  &:before {
    content: $dx-icon-agi-heart; 
  }
}
.dx-icon-agi-heart-o {
  &:before {
    content: $dx-icon-agi-heart-o; 
  }
}
.dx-icon-agi-trophy {
  &:before {
    content: $dx-icon-agi-trophy; 
  }
}
.dx-icon-agi-home {
  &:before {
    content: $dx-icon-agi-home; 
  }
}
.dx-icon-agi-cog {
  &:before {
    content: $dx-icon-agi-cog; 
  }
}
.dx-icon-agi-envelope {
  &:before {
    content: $dx-icon-agi-envelope; 
  }
}
.dx-icon-agi-globe {
  &:before {
    content: $dx-icon-agi-globe; 
  }
}
.dx-icon-agi-star {
  &:before {
    content: $dx-icon-agi-star; 
  }
}
.dx-icon-agi-tag {
  &:before {
    content: $dx-icon-agi-tag; 
  }
}
.dx-icon-agi-folder {
  &:before {
    content: $dx-icon-agi-folder; 
  }
}
.dx-icon-agi-trash-alt {
  &:before {
    content: $dx-icon-agi-trash-alt; 
  }
}
.dx-icon-agi-inbox {
  &:before {
    content: $dx-icon-agi-inbox; 
  }
}
.dx-icon-agi-shipping-fast {
  &:before {
    content: $dx-icon-agi-shipping-fast; 
  }
}
.dx-icon-agi-user-circle {
  &:before {
    content: $dx-icon-agi-user-circle; 
  }
}
.dx-icon-agi-user {
  &:before {
    content: $dx-icon-agi-user; 
  }
}
.dx-icon-agi-user-gear {
  &:before {
    content: $dx-icon-agi-user-gear; 
  }
}
.dx-icon-agi-people-group {
  &:before {
    content: $dx-icon-agi-people-group; 
  }
}
.dx-icon-agi-file-csv {
  &:before {
    content: $dx-icon-agi-file-csv; 
  }
}
.dx-icon-agi-copy {
  &:before {
    content: $dx-icon-agi-copy; 
  }
}
.dx-icon-agi-comment-dots {
  &:before {
    content: $dx-icon-agi-comment-dots; 
  }
}
.dx-icon-agi-ban {
  &:before {
    content: $dx-icon-agi-ban; 
  }
}
.dx-icon-agi-thumbs-up {
  &:before {
    content: $dx-icon-agi-thumbs-up; 
  }
}
.dx-icon-agi-thumbs-down {
  &:before {
    content: $dx-icon-agi-thumbs-down; 
  }
}
.dx-icon-agi-twitter {
  &:before {
    content: $dx-icon-agi-twitter; 
  }
}
.dx-icon-agi-linkedin-in {
  &:before {
    content: $dx-icon-agi-linkedin-in; 
  }
}
.dx-icon-agi-whatsapp {
  &:before {
    content: $dx-icon-agi-whatsapp; 
  }
}
.dx-icon-agi-instagram {
  &:before {
    content: $dx-icon-agi-instagram; 
  }
}
.dx-icon-agi-facebook-f {
  &:before {
    content: $dx-icon-agi-facebook-f; 
  }
}
.dx-icon-agi-telegram-plane {
  &:before {
    content: $dx-icon-agi-telegram-plane; 
  }
}
.dx-icon-agi-circle-check {
  &:before {
    content: $dx-icon-agi-circle-check; 
  }
}
.dx-icon-agi-exclamation-circle {
  &:before {
    content: $dx-icon-agi-exclamation-circle; 
  }
}
.dx-icon-agi-circle-pause {
  &:before {
    content: $dx-icon-agi-circle-pause; 
  }
}
.dx-icon-agi-circle-question {
  &:before {
    content: $dx-icon-agi-circle-question; 
  }
}
.dx-icon-agi-circle-play {
  &:before {
    content: $dx-icon-agi-circle-play; 
  }
}
.dx-icon-agi-key {
  &:before {
    content: $dx-icon-agi-key; 
  }
}
.dx-icon-agi-up-right-and-down-left-from-center {
  &:before {
    content: $dx-icon-agi-up-right-and-down-left-from-center; 
  }
}
.dx-icon-agi-eye {
  &:before {
    content: $dx-icon-agi-eye; 
  }
}
.dx-icon-agi-eye-slash {
  &:before {
    content: $dx-icon-agi-eye-slash; 
  }
}
.dx-icon-agi-rotate-right {
  &:before {
    content: $dx-icon-agi-rotate-right; 
  }
}
.dx-icon-agi-filter {
  &:before {
    content: $dx-icon-agi-filter; 
  }
}
.dx-icon-agi-lock {
  &:before {
    content: $dx-icon-agi-lock; 
  }
}
.dx-icon-agi-address-card {
  &:before {
    content: $dx-icon-agi-address-card; 
  }
}
.dx-icon-agi-wrench {
  &:before {
    content: $dx-icon-agi-wrench; 
  }
}
.dx-icon-agi-tools {
  &:before {
    content: $dx-icon-agi-tools; 
  }
}
.dx-icon-agi-picture {
  &:before {
    content: $dx-icon-agi-picture; 
  }
}
.dx-icon-agi-claim {
  &:before {
    content: $dx-icon-agi-claim; 
  }
}
.dx-icon-agi-verified {
  &:before {
    content: $dx-icon-agi-verified; 
  }
}
.dx-icon-agi-list-add {
  &:before {
    content: $dx-icon-agi-list-add;
  }
}
.dx-icon-agi-list-remove {
  &:before {
    content: $dx-icon-agi-list-remove;
  }
}
