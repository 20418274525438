@use 'variables' as var;

.ag-grid {
  height: 100%;
  border-radius: #{var.$base-padding};
  overflow: hidden;

  &__toolbar-title {
    margin-top: 9.5px;
    min-width: 75px;
  }

  .dx-datagrid-header-panel {
    margin-top: 10px;
    padding: 0 #{var.$base-padding};
  }
}