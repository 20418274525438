@import "variables";

html,
body {
  margin: 0;
  min-height: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

.content {
  line-height: 1.5;

  h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.side-nav-outer-toolbar .dx-drawer {
  height: calc(100% - 56px);
}

.content-block {
  margin: calc(#{$base-padding}) calc(3 * #{$base-padding}) 0;

  .screen-x-small & {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer > .dx-scrollable-wrapper > .dx-scrollable-container > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

$side-panel-min-width: 60px;

.dx-item:has(> .dx-item-content > .min-width-min-content) {
  min-width: min-content!important;
}

.dx-flex-layout:has(> .dx-field-item-content > .dx-swipeable) {
  margin-top: 7.5px;
}

.dx-field-item-help-text {
  padding-left: 8px;
}