.common {
  &__navigate-back-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    color: #333;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
    & &-icon,
    & &-content {
      color: #333;
    }
  }
}
