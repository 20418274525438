.ag-select-box-drop-down {
  .dx-popup-normal {
    min-width: min-content;

    .dx-item.dx-list-item {
      width: auto;
      .dx-item-content.dx-list-item-content {
        width: min-content;
      }
    }
  }
}

.ag-summary-table {
  --ag-summary-table-min-width: 200px;
  min-width: var(--ag-summary-table-min-width);

  .dx-toolbar-items-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    min-height: 36px;
    height: auto; // fix hardcoded height that cut off items in toolbar

    .dx-toolbar-before,
    .dx-toolbar-center,
    .dx-toolbar-after {
      position: static; // fix position absolute in toolbar that overlaps items
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
